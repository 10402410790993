import axios from 'axios';
import { apiErrorEventTarget } from '../modules/shared/events';

// export const API_ENDPOINT = process.env.REACT_APP_API_URL ?? 'https://staged.heimlich.com/api/';
// export const API_ENDPOINT = 'https://prod-api.heimlich.com/api/';
// export const API_ENDPOINT = 'https://prod-api.besecret.com/api/';

// export const API_ENDPOINT = process.env.REACT_APP_API_URL ?? 'https://staged.heimlich.com/api/';
export const API_ENDPOINT = 'https://staged.heimlich.com/api/';
// export const API_ENDPOINT = 'http://192.168.31.124:8000/api/';

export const api = axios.create({
    baseURL: API_ENDPOINT,
});

api.interceptors.response.use(
    function (response) {
        if (response.headers['content-type'] === 'application/json') {
            if (response.data.error) {
                apiErrorEventTarget.dispatchEvent(
                    new CustomEvent('onApiError', {
                        detail: {
                            message: response.data.error,
                            statusCode: response.status,
                        },
                    }),
                );
                return Promise.reject(response);
            }
        }
        return response;
    },
    function (error) {
        return Promise.reject(error);
    },
);

api.interceptors.request.use(
    function (config) {
        let devicetoken = localStorage.getItem('devicetoken');
        if (devicetoken === 'null' || !devicetoken) {
            devicetoken = 'a70417b3-ef39-40de-968d-5cf5d2bf187b';
        }

        if (devicetoken) {
            config.headers.devicetoken = devicetoken;
            config.headers.devicetype = 'web';
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    },
);

export default class BaseService {
    prefix = null;
    api = api;

    version = 2.2;
    API_ENDPOINT = API_ENDPOINT;

    constructor(prefix) {
        this.prefix = prefix;
    }

    get headers() {
        const headers = {
            HEIMLICHAPPVERSION: this.version,
        };
        const token = localStorage.getItem('token');
        if (token) {
            headers.Authorization = `Bearer ${token}`;
        }
        return headers;
    }

    get configs() {
        return {
            headers: this.headers,
        };
    }

    get(route, params) {
        return this.api.get(`${this.prefix}${route}`, {
            ...this.configs,
            params,
        });
    }

    put(route, body) {
        return this.api.put(`${this.prefix}${route}`, body, this.configs);
    }

    post(route, body) {
        return this.api.post(`${this.prefix}${route}`, body, this.configs);
    }

    delete(route, body) {
        return this.api.delete(`${this.prefix}${route}`, this.configs);
    }
}
